var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}],staticClass:"align-items-center auth-bg px-2 p-lg-2",attrs:{"lg":"12"}},[_c('div',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_c('h4',[_vm._v("Kindly fill the form below to register a user")])]),_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#000000","title":null,"subtitle":null,"shape":"circle","step-size":"xs","finish-button-text":"Submit User","back-button-text":"Previous"},on:{"on-complete":_vm.submitClientUser}},[_c('tab-content',{attrs:{"title":"User Information","before-change":_vm.validationFormAdministrator}},[_c('validation-observer',{ref:"administratorRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Select Company","label-for":"client_id"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"id":"client_id","state":errors.length > 0 ? false:null},model:{value:(_vm.clientUserform.client_id),callback:function ($$v) {_vm.$set(_vm.clientUserform, "client_id", $$v)},expression:"clientUserform.client_id"}},_vm._l((_vm.clients),function(client,index){return _c('el-option',{key:index,attrs:{"value":client.id,"label":client.name}})}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"placeholder":"Enter First Name"},model:{value:(_vm.clientUserform.admin_first_name),callback:function ($$v) {_vm.$set(_vm.clientUserform, "admin_first_name", $$v)},expression:"clientUserform.admin_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Enter Last Name"},model:{value:(_vm.clientUserform.admin_last_name),callback:function ($$v) {_vm.$set(_vm.clientUserform, "admin_last_name", $$v)},expression:"clientUserform.admin_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Valid Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Enter Email Address","state":errors.length > 0 ? false:null},model:{value:(_vm.clientUserform.email),callback:function ($$v) {_vm.$set(_vm.clientUserform, "email", $$v)},expression:"clientUserform.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('br'),_c('small',{staticClass:"text-primary"},[_c('strong',[_vm._v("On successful registration a notification will be sent to this e-mail")])])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"Enter Phone Number"},model:{value:(_vm.clientUserform.phone),callback:function ($$v) {_vm.$set(_vm.clientUserform, "phone", $$v)},expression:"clientUserform.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation","rules":"integer:min:11|integer:max:11"}},[_c('b-form-input',{attrs:{"id":"phone2","placeholder":"Your designation at work"},model:{value:(_vm.clientUserform.designation),callback:function ($$v) {_vm.$set(_vm.clientUserform, "designation", $$v)},expression:"clientUserform.designation"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }